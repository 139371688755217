import React from 'react'
import PropTypes from 'prop-types'

import Loadable from 'react-loadable'

import { complement, isEmpty, isNil, pathOr } from 'ramda'
import { HashRouter as Router, Route, Switch, withRouter } from 'react-router-dom'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { UIBlock, UILayout } from 'bora-material-ui'

import StepsRouter from './StepsRouter'

import { SCHEDULES_PATH } from '../../../../consts/links'
import UIElements from '../../../../consts/UIElements'

import { actions, getPaymentErrorDetails, selectors } from '../../provider'
import { goToPage } from '../../../../actions'
import { getLocale } from '../../../../services/user-selections'
import { getErrorDetails } from '../../../../services/reservation/selectors'

import { notEmpty } from '../../../../utils/ramda-extend'

import { SubInfo, Warning } from '../../../Common'

import { ConfirmPayLoading, DateTimeLoading, SelectTicketsLoading } from './Loaders'
import { isWeightRequest } from '../../../../reducers/network'
import { isUnrecoverableError } from '../../../Common/known-errors'
import { clearReservationError } from '../../../../services/reservation/actions/reservation'
import { getMaritimeStyle } from '../../../../utils/maritimeStyleUtils'
import { getStyle } from '../../../../utils/liinilaevadThemeUtils'

const getReservationId = pathOr('', ['match', 'params', 'reservationId'])
const getSailRefId = pathOr('', ['match', 'params', 'sailRefId'])

// eslint-disable-next-line react/prefer-stateless-function

const styles = {
  wrapperXs: { margin: '0', width: 'auto' },
}

const LoadableDateTime = Loadable({
  loader: () => import('./Steps/DateTime/index.js'),
  loading: DateTimeLoading,
})

const LoadableSelectTickets = Loadable({
  loader: () => import('./Steps/SelectTicket/index.js'),
  loading: SelectTicketsLoading,
})

const LoadableConfirmPay = Loadable({
  loader: () => import('./Steps/ConfirmPay/index.js'),
  loading: ConfirmPayLoading,
})

export const shouldErrorBeIgnored = ({ code = '' }, isLastRequestAboutWeightChange = false) => {
  return isLastRequestAboutWeightChange && code && code === 'FAILED_TO_ALLOCATE_CAR_DECK_INVENTORY_BY_WEIGHT'
}

const MainInsideView = (props, context) => {
  const mainLeftPadding = '36px'
  const { muiTheme } = context
  const {
    locale,
    paymentError,
    selectedSailPackages,
    selectTicketsStepAvailable,
    confirmAndPayStepAvailable,
    reservationError,
    errorDetails = {},
    isLastRequestAboutWeightChange = false,
    clearReservationError,
  } = props
  const [sailPackage = {}] = selectedSailPackages

  const errorOccurred =
    !shouldErrorBeIgnored(errorDetails, isLastRequestAboutWeightChange) && (paymentError || reservationError)
  const onlyError = Boolean(reservationError)

  const goToSchedules = () => props.goToPage(SCHEDULES_PATH + (sailPackage.code || '').replace('-', ''))

  let loadReservationDetails = true

  if (errorDetails && errorDetails.code && isUnrecoverableError(errorDetails.code)) {
    loadReservationDetails = false
  }
  const customMargin =
    window.brandProps.muiTheme.appBar && window.brandProps.muiTheme.appBar.margin
      ? window.brandProps.muiTheme.appBar.margin
      : '40px'
  return (
    <UILayout
      column
      j-flex-start
      padding={UIElements.CONTENT_PADDING}
      margin={getStyle('0px', `${UIElements.HEADER ? '-97px' : customMargin} 0 0`)}
      xs={styles.wrapperXs}
      {...muiTheme.ids.mainInsideContent}
    >
      <UIBlock
        width="100%"
        margin="0 auto 0 0"
        bgColor={getMaritimeStyle('white', '#fafafa')}
        {...muiTheme.ids.BlockStyle}
      >
        <StepsRouter
          locale={locale}
          errorOccurred={errorOccurred}
          goToSchedules={goToSchedules}
          selectTicketsStepAvailable={selectTicketsStepAvailable}
          confirmAndPayStepAvailable={confirmAndPayStepAvailable}
          onPopStateChange={clearReservationError}
        />

        <Warning
          onlyError={onlyError}
          paymentError={Boolean(paymentError)}
          display-if={errorOccurred}
          errorMessage={errorOccurred}
          errorDetails={errorDetails}
        />

        <SubInfo />

        <Router>
          <Switch>
            <Route
              path="/:locale/main-inside/select-datetime"
              component={() => <LoadableDateTime mainLeftPadding={mainLeftPadding} />}
            />
            <Route
              path="/:locale/main-inside/select-tickets"
              render={() =>
                loadReservationDetails ? <LoadableSelectTickets mainLeftPadding={mainLeftPadding} /> : null
              }
            />
            <Route
              path="/:locale/main-inside/sail/:sailRefId"
              render={(props) => {
                const sailRefId = getSailRefId(props)
                return <LoadableSelectTickets sailRefId={sailRefId} mainLeftPadding={mainLeftPadding} />
              }}
            />
            <Route
              path="/:locale/main-inside/confirm/:reservationId"
              render={(props) => {
                const reservationId = getReservationId(props)
                return (
                  <LoadableConfirmPay
                    paymentError={`Payment test error occurred for reservation ${reservationId}`}
                    reservationId={reservationId}
                    mainLeftPadding={mainLeftPadding}
                  />
                )
              }}
            />
            <Route
              path="/:locale/main-inside/confirm"
              render={() => <LoadableConfirmPay mainLeftPadding={mainLeftPadding} />}
            />
          </Switch>
        </Router>
      </UIBlock>
    </UILayout>
  )
}

MainInsideView.contextTypes = {
  muiTheme: PropTypes.object,
}

MainInsideView.propTypes = {
  selectTicketsStepAvailable: PropTypes.bool.isRequired,
  confirmAndPayStepAvailable: PropTypes.bool.isRequired,
  locale: PropTypes.string.isRequired,
  goToPage: PropTypes.func.isRequired,
  clearReservationError: PropTypes.func.isRequired,
  paymentError: PropTypes.any,
  selectedSailPackages: PropTypes.array,
  reservationError: PropTypes.any,
  isLastRequestAboutWeightChange: PropTypes.bool,
}

MainInsideView.defaultProps = {
  paymentError: false,
  selectedSailPackages: [],
  reservationError: false,
  isLastRequestAboutWeightChange: false,
}

const mapDispatchToProps = (dispatch) => bindActionCreators({ ...actions, goToPage, clearReservationError }, dispatch)

export default connect((state, { location = {} }) => {
  const reservation = selectors.getReservationSelector(state)
  const prices = selectors.getPrices(state)
  const selectedSailRefIds = selectors.getSelectedSailRefIdList(state)
  const allPricesFetched = selectedSailRefIds.every((id) => prices[id])

  let redirectError

  if (window.URLSearchParams) {
    const searchParams = new URLSearchParams(location.search)

    if (searchParams.has('error')) {
      redirectError = searchParams.get('error')
    }
  }

  const reservationError = state.reservation.failed || false
  let errorDetails = getErrorDetails(state)

  if (isEmpty(errorDetails)) errorDetails = getPaymentErrorDetails(state)

  return {
    locale: getLocale()(state),
    paymentError: selectors.getPaymentError(state) || redirectError,
    selectedSailPackages: selectors.getSelectedSailPackages(state),
    confirmAndPayStepAvailable: complement(isNil)(reservation.current) && reservation.confirmed,
    selectTicketsStepAvailable: allPricesFetched && notEmpty(selectors.selectedSails(state)),
    reservationError,
    errorDetails,
    isLastRequestAboutWeightChange: isWeightRequest(state),
  }
}, mapDispatchToProps)(withRouter(MainInsideView))
