import { UIButton, UIIcon, UILayout } from 'bora-material-ui'
import React, { useEffect, useState } from 'react'
import cn from 'classnames'
import axios from 'axios'
import { connect } from 'react-redux'
import { getCurrentReservation, getCurrentReservationId } from '../../../../../../../services/reservation/selectors'
import { PAYMENT_PAY_FAILED } from '../../../../../../../services/payment/consts/payment'
import { translate } from '../../../../../../Common/Translator'
import messages from '../../../../../../../consts/messages'
import { getLocale } from '../../../../../../../services/user-selections'
import { usePaymentButtonCooldown } from './usePaymentButtonCooldown'

export const BUTTON_BLOCK_TIMESTAMP_KEY = 'applePayClickStamp'

const ApplePayMethod = ({
  disabled,
  isLoading,
  paymentMethod,
  reservationToken,
  reservationId,
  moduleStyles,
  paymentPayFailed,
  locale = 'en',
}) => {
  const [transaction, setTransaction] = useState()
  const [preparing, setPreparing] = useState(false)

  const payButtonBlocked = usePaymentButtonCooldown({ reservationToken, locale })
  useEffect(() => {
    if (window.applePay && window.applePay.LoadApplePay) {
      setTimeout(() => {
        setPreparing(true)
        window.applePay.LoadApplePay('ADM Ferry Collection')
        axios({
          method: 'POST',
          url: `/api/payment/${reservationToken}/prepare`,
          data: {
            paymentMethod,
            origin: window.origin,
            creditCard: false,
          },
        })
          .then(setTransaction)
          .catch((err) => console.error(err))
          .finally(() => setPreparing(false))
      }, 350)
    } else {
      console.error('window.applePay is not in global or applePay.LoadApplePay is undefined')
    }
  }, [reservationId, setTransaction, paymentMethod, reservationToken])

  console.log('transaction = ', transaction)

  const handleApplePayClick = () => {
    setPreparing(true)
    window.boraPayment = {
      reservationId,
      reservationToken,
      paymentMethod,
      paymentPayFailedHandler: paymentPayFailed,
      expireMessage: translate(messages.paymentExpiredPopUpMessage),
      locale,
    }
    window.applePay.BeginApplePayPayment(transaction)
    setPreparing(false)
  }

  return (
    <UILayout
      margin="3px"
      width="auto"
      blink={disabled && isLoading}
      className={cn(moduleStyles.formDirection, 'apple-pay')}
      id="apple-pay-button"
    >
      <UIButton
        className={moduleStyles.endMargin10px}
        background="black"
        width="300px"
        borderRadius="6px"
        data-testid="payment-method"
        disabled={disabled || preparing || payButtonBlocked}
        height="58px"
        click={handleApplePayClick}
      >
        <UILayout j-flex-center center height="100%">
          <UIIcon type="iconApplePay" fill="white" />
        </UILayout>
      </UIButton>
    </UILayout>
  )
}

const mapStateToProps = (state) => {
  const { reservationOwner, token } = getCurrentReservation(state)
  const reservationId = getCurrentReservationId(state)

  return {
    reservationOwner,
    reservationId,
    reservationToken: token,
    locale: getLocale(state),
  }
}

const mapDispatchToProps = (dispatch) => ({
  paymentPayFailed: (error) =>
    dispatch({
      type: PAYMENT_PAY_FAILED,
      payload: error,
    }),
})

export default connect(mapStateToProps, mapDispatchToProps)(ApplePayMethod)
